import logo from "./logo.png";
import styles from "./App.module.css";
import { BrowserRouter, Routes, Route, Outlet, Link } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <nav className={styles.navbar}>
        <ul>
          <li>
            <Link to={"/"}>Landing Page</Link>
          </li>
          <li>
            <Link to={"/salesPage"}>Sales Page</Link>
          </li>
        </ul>
      </nav>

      <div className={styles.root}>
        <Outlet />
      </div>
    </>
  );
};

function Home() {
  return (
    <>
      <h1>Landing Page 4018</h1>
      <article>
        <img src={logo} style={{ width: "250px" }} alt="" />
      </article>
    </>
  );
}
function SalesPage() {
  return (
    <>
      <h1>Sales Page 4018</h1>
      <article style={{ backgroundColor: "violet" }}>
        <p>Buy</p>
        <p>-20%</p>
        <p>-50%</p>
        <p>-10%</p>
      </article>
    </>
  );
}

function NoPage() {
  return <h1>NoPage</h1>;
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={`/`} element={<Layout />}>
          <Route index element={<Home />} />
          <Route path={`salesPage`} element={<SalesPage />} />
          <Route path={"/*"} element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
